import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import SubLeftbarWrapperStyled from './styledComponent'

class SubLeftbarWrapper extends PureComponent {
  render() {
    return <SubLeftbarWrapperStyled className={this.props.className}>{this.props.children}</SubLeftbarWrapperStyled>
  }
}

SubLeftbarWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  className: PropTypes.string
}

export default SubLeftbarWrapper
